// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query DossierNotesByBlockId(
    $dossierId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
    $blockId: ID!,
    $first: Int,
    $page: Int,
    $filters: [DataGridFilterInput],
    $sorters: [DataGridSorterInput]
  ) {
    list: dossierNotesByBlockId(
      dossierId: $dossierId,
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
      blockId: $blockId,
      first: $first,
      page: $page,
      filters: $filters,
      sorters: $sorters
    ) {
      data {
        data
      }
      dataGridInfo {
        columns {
          name
          label
        }
        filters {
          name
          applied
          data
          metadata
          __typename
        }
        sorters {
          name
          applied
          direction
        }
        metadata
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export default QUERY;
